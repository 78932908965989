import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router/index";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: true });

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    user: {
      userid: "",
      username: "",
      allow_updates: null,
      extra_updates: null,
      show_return: null,
      add_chassis: null,
      officephone: null,
      company_logo: null,
      status: null,
      authenticated: false,
    },
    //main_domain: "https://localdev", //no trailer slash
    main_domain: "https://xwaybill.com", //no trailer slash    
    domain: "",
    token: null,
    loading: false,
    message: { type: null, text: null },
    currentOrders: [],
    currentOrder: {},
    defaultChassis: null,
    currentWeight: null,
    currentSealNo: null,
    currentBOLNo: null,
    timeInByDriver: null,
  },
  plugins: [
    createPersistedState({
      key: "vuex-key",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      reducer(val) {
        //use to remove the vuex persistent state on logout
        if (val.user === null) {
          return {};
        }
        return val;
      },
    }),
  ],
  mutations: {
    setDomain(state, domain) {
      state.domain = domain;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAuthUser(state, user) {
      state.user = user;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setMessage(state, payload) {
      state.message = { type: payload.type, text: payload.text };
    },
    clearMessage(state) {
      state.message = { type: null, text: null };
    },
    removeAuthUser(state) {
      state.user = {
        userid: "",
        username: "",
        allow_updates: null,
        extra_updates: null,
        show_return: null,
        add_chassis: null,
        officephone: null,
        company_logo: null,
        status: null,
        authenticated: false,
      };
    },
    setCurrentOrders(state, payload) {
      state.currentOrders = payload;
    },
    setCurrentOrder(state, payload) {
      state.currentOrder = payload;
    },
    clearCurrentOrder(state) {
      state.currentOrder = {};
    },
    setDefaultChassis(state, payload) {
      state.defaultChassis = payload;
    },
    setWeight(state, payload) {
      state.currentWeight = payload;
    },    
    setSealNo(state, payload) {
      state.currentSealNo = payload;
    },
    setBOLNo(state, payload) {
      state.currentBOLNo = payload;
    },    
    setTimeInByDriver(state, payload) {
      state.timeInByDriver = payload;
    },    
  },
  actions: {
    loginUser({ commit }, payload) {
      commit("clearMessage");

      //driver login axios API
      axios
        .post(this.state.main_domain + "/" + payload.domain + "/api/driverLogin/", {
          username: payload.username,
          password: btoa(payload.password),
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", { type: "error", text: response.data.status });
            commit("removeAuthUser");
            commit("setDomain", "");
            commit("setToken", null);
          } else {
            const authUser = {
              userid: response.data.results.driver_id,
              username: response.data.results.driver_name,
              allow_updates: response.data.results.allow_updates,
              extra_updates: response.data.results.extra_updates,
              show_return: response.data.results.show_return,
              add_chassis: response.data.results.add_chassis,
              officephone: response.data.results.office_phone,
              company_logo: response.data.results.company_logo,
              status: response.data.results.status,
              authenticated: true,
            };
            commit("setAuthUser", authUser);
            commit("setDomain", this.state.main_domain + "/" + payload.domain);
            commit("setToken", response.data.results.auth_token);
            router.push("./orders");
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
          commit("removeAuthUser");
          commit("setDomain", "");
          commit("setToken", null);
        });
    },
    logoutUser({ commit }) {
      localStorage.removeItem("vuex-key");
      commit("clearMessage");
      commit("removeAuthUser");
      commit("setDomain", "");
      commit("setToken", null);
      commit("setLoading", false);
      commit("setCurrentOrders", []);
      commit("setCurrentOrder", {});
      commit("setDefaultChassis", null);
      commit("setWeight", null);
      commit("setSealNo", null);
      commit("setBOLNo", null);
      commit("setTimeInByDriver", null);
      localStorage.removeItem("transit_distance");
      localStorage.removeItem("transit_time");
      localStorage.removeItem("eta_distance");
      localStorage.removeItem("eta_time");
      localStorage.removeItem("route_distance");
      localStorage.removeItem("route_time");
    },
    loadCurrentOrders({ commit }) {
      commit("clearMessage");
      commit("setLoading", true);

      const domain = this.state.domain;
      axios
        .post(domain + "/api/getMovements/", {
          auth_token: this.state.token,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setLoading", false);
            commit("setMessage", { type: "error", text: response.data.status });
            commit("setCurrentOrders", []);
          } else {
            commit("setCurrentOrders", response.data.results);
          }
          commit("setDefaultChassis", null);
          //console.log("default chassis: " + this.state.defaultChassis);
          commit("setLoading", false);
        })
        .catch((error) => {
          console.log(error);
          commit("setLoading", false);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },
    clearCurrentOrder({ commit }) {
      commit("clearCurrentOrder");
    },
    checkDriverStatus({ commit }) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/driverStatus/", {
          auth_token: this.state.token,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            router.push("./../orders");
          } else {
            return (this.state.user.status = response.data.results.status);
          }
        })
        .catch((error) => {
          //message send error
          console.log(error);
        });
    },
    sendReadReceipt({ commit }, move_id) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/readReceipt/", {
          auth_token: this.state.token,
          id: move_id,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", {
              type: "error",
              text: "Read Receipt wasn't sent to dispatch",
            });
          } else {
            if (response.data.results.read_receipt_saved == false) {
              commit("setMessage", {
                type: "error",
                text: "Read Receipt wasn't sent to dispatch",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },
    sendCheckInReceipt({ commit }, move_id) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/checkInReceipt/", {
          auth_token: this.state.token,
          id: move_id,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", {
              type: "error",
              text: "Check-in Receipt wasn't sent to dispatch",
            });
          } else {
            if (response.data.results.checkin_receipt_saved == false) {
              commit("setMessage", {
                type: "error",
                text: "Check-in Receipt wasn't sent to dispatch",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },
    updateChassis({ commit }, payload) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/updateChassis/", {
          auth_token: this.state.token,
          id: payload.move_id,
          date: payload.appt_date,
          chassis: payload.chassis,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", { type: "error", text: response.data.status });
          } else {
            if (response.data.results.chassis_updated == "notexist") {
              commit("setMessage", {
                type: "error",
                text:
                  "Chassis doesn't exist, inactive, or needs repair. Please check your input or call the dispatcher.",
              });
            } else if (
              response.data.results.chassis_updated == "notavailable"
            ) {
              commit("setMessage", {
                type: "error",
                text:
                  "Chassis taken by " +
                  response.data.results.taken_by_driver +
                  ". Please call the dispatcher.",
              });
            } else if (response.data.results.chassis_updated == false) {
              commit("setMessage", {
                type: "error",
                text: "Chassis not updated, please call the dispatcher",
              });
            } else if (response.data.results.chassis_updated == "updated") {
              commit("setMessage", {
                type: "success",
                text: "Chassis already updated",
              });
            } else if (response.data.results.chassis_updated == true) {
              commit("setDefaultChassis", payload.chassis);
              //console.log("default chassis: " + this.state.defaultChassis);
              commit("setMessage", {
                type: "success",
                text: "Chassis updated successfully",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },
    addChassis({ commit }, payload) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/addChassis/", {
          auth_token: this.state.token,
          id: payload.move_id,
          date: payload.appt_date,
          chassis: payload.new_chassis,
          type: payload.chassis_type
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", { type: "error", text: response.data.status });
          } else {
            if (response.data.results.chassis_added == false) {
              commit("setMessage", {
                type: "error",
                text: "Chassis is not added due to an error, please call the dispatcher",
              });
            } else if (response.data.results.chassis_added == "exist") {
              commit("setDefaultChassis", payload.chassis);
              commit("setMessage", {
                type: "error",
                text: "Chassis already exist, you may update it or call the dispatcher",
              });
            } else if (response.data.results.chassis_added == true) {
              //console.log("default chassis: " + this.state.defaultChassis);
              commit("setMessage", {
                type: "success",
                text: "Chassis added and updated successfully",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    }, 
    updateWeight({ commit }, payload) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/updateWeight/", {
          auth_token: this.state.token,
          id: payload.move_id,
          date: payload.appt_date,
          weight: payload.weight,
          weight_type: payload.weight_type
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", { type: "error", text: response.data.status });
          } else {
            if (response.data.results.weight_updated == false) {
              commit("setMessage", {
                type: "error",
                text:
                  "Weight not updated, please call the dispatcher",
              });
            } else if (response.data.results.weight_updated == true) {
              commit("setWeight", payload.weight);
              commit("setMessage", {
                type: "success",
                text: "Weight updated successfully",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },    
    updateSealNo({ commit }, payload) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/updateSealNo/", {
          auth_token: this.state.token,
          id: payload.move_id,
          date: payload.appt_date,
          sealno: payload.sealno,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", { type: "error", text: response.data.status });
          } else {
            if (response.data.results.seal_updated == false) {
              commit("setMessage", {
                type: "error",
                text:
                  "Seal number not updated, please call the dispatcher",
              });
            } else if (response.data.results.seal_updated == true) {
              commit("setSealNo", payload.sealno);
              commit("setMessage", {
                type: "success",
                text: "Seal number updated successfully",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },
    updateBOLNo({ commit }, payload) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/updateBOLNo/", {
          auth_token: this.state.token,
          id: payload.move_id,
          date: payload.appt_date,
          bolno: payload.bolno,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", { type: "error", text: response.data.status });
          } else {
            if (response.data.results.bol_updated == false) {
              commit("setMessage", {
                type: "error",
                text:
                  "Bill Of Lading not updated, please call the dispatcher",
              });
            } else if (response.data.results.bol_updated == true) {
              commit("setBOLNo", payload.bolno);
              commit("setMessage", {
                type: "success",
                text: "Bill Of Lading updated successfully",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },    
    updateContainerNumber({ commit }, payload) {
      commit("clearMessage");
      const domain = this.state.domain;
      axios
        .post(domain + "/api/updateContainerNumber/", {
          auth_token: this.state.token,
          waybill: payload.waybill_id,
          id: payload.container_id,
          number: payload.container_number,
        })
        .then((response) => {
          if (response.data.status != "Success") {
            commit("setMessage", { type: "error", text: response.data.status });
          } else {
            if (response.data.results.container_updated == "notexist") {
              commit("setMessage", {
                type: "error",
                text: "Container don't exist, please check your input",
              });
            } else if (response.data.results.container_updated == false) {
              commit("setMessage", {
                type: "error",
                text: "Container not updated, please call the dispatcher",
              });
            } else if (response.data.results.container_updated == "updated") {
              commit("setMessage", {
                type: "success",
                text: "Container already updated",
              });
            } else if (response.data.results.container_updated == true) {
              commit("setMessage", {
                type: "success",
                text: "Container updated successfully",
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },
    saveSignature({ commit }, payload) {
      const domain = this.state.domain;
      axios
        .post(domain + "/api/saveSignature/", {
          auth_token: this.state.token,
          id: payload.move_id,
          name: payload.name_pod,
          email: payload.email_pod,
          time_in: payload.time_in,
          time_out: payload.time_out,
          sstr: payload.signature_pod,
          d_status: payload.dispatched_status
        })
        .then((response) => {
          if (response.data.status != "Success") {
            //DB error
            commit("setMessage", {
              type: "error",
              text: response.data.status,
            });
          } else if (response.data.results.signature_saved == false) {
            //Not saved
            commit("setMessage", {
              type: "error",
              text:
                "The signature is NOT saved. Please take the signature on the hard copy.",
            });
          } else {
            //Success
            commit("setMessage", {
              type: "success",
              text: "Signature saved successfully",
            });
            commit("setTimeInByDriver", null);
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setMessage", {
            type: "error",
            text: "xDriver can't connect to the main server",
          });
        });
    },
    updateSettings({ commit }, payload) {
      commit("clearMessage");
      localStorage.setItem("maps_uom", payload.maps_uom);
      localStorage.setItem("maps_type", payload.maps_type);
      localStorage.setItem("maps_truck_restrictions", payload.maps_truck_restrictions);
      commit("setMessage", {
        type: "success",
        text: "Settings saved successfully",
      });
    },
    setDefaultChassis({ commit }, payload) {
      commit("setDefaultChassis", payload);
    },
    setWeight({ commit }, payload) {
      commit("setWeight", payload);
    },    
    setSealNo({ commit }, payload) {
      commit("setSealNo", payload);
    },   
    setBOLNo({ commit }, payload) {
      commit("setBOLNo", payload);
    },     
    setTimeInByDriver({ commit }, payload) {
      commit("setTimeInByDriver", payload);
    },    
    savePhoto({ commit }, payload) {
      commit("clearMessage");
      console.log(payload);
      // const domain = this.state.domain;
      // axios
      //   .post(domain + "/api/savePhoto/", {
      //     auth_token: this.state.token,
      //     id: payload.move_id,
      //     photo: ''
      //   })
      //   .then((response) => {
      //     if (response.data.status != "Success") {
      //       commit("setMessage", { type: "error", text: response.data.status });
      //     } else {
      //       if (response.data.results.photo_saved == true) {
      //         //console.log("default chassis: " + this.state.defaultChassis);
      //         commit("setMessage", {
      //           type: "success",
      //           text: "Photo saved successfully",
      //         });
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     commit("setMessage", {
      //       type: "error",
      //       text: "xDriver can't connect to the main server",
      //     });
      //   });
    },
    setMessage({ commit }, payload) {
      commit("setMessage", payload);
    },
    clearMessage({ commit }) {
      commit("clearMessage");
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    domain(state) {
      return state.domain;
    },
    token(state) {
      return state.token;
    },
    loading(state) {
      return state.loading;
    },
    message(state) {
      return state.message;
    },
    currentOrders(state) {
      return state.currentOrders;
    },
    currentOrder(state) {
      return state.currentOrder;
    },
    defaultChassis(state) {
      return state.defaultChassis;
    },
    currentWeight(state) {
      return state.currentWeight;
    },    
    currentSealNo(state) {
      return state.currentSealNo;
    },
    currentBOLNo(state) {
      return state.currentBOLNo;
    },    
    timeInByDriver(state) {
      return state.timeInByDriver;
    },    
  },
});
